
import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image, ImageBackground, Linking } from 'react-native';
import TopBar from './TopBar';
const Container = ({setLayout}) => {
    const { styles } = useStyles();

    const onLayout = (event) => {
        const { height } = event.nativeEvent.layout;
        setLayout(height);
    }

    const services = [
        {
            title: "תיקון מכירה והתקנה",
            icon: require('../../public/icons8-tools-96.png'),
            size: 30
        },
        { title: "שחזור מידע והחלפת מסכים", icon: require('../../public/icons8-hand-with-smartphone-96.png'), size: 30 },
        { title: "מכירת אביזרים", icon: require('../../public/smartphone-case.png'), size: 40 },
        // { title: "שירות עד בית הלקוח", icon: require('../../public/icons8-house-48.png'), size: 30 },
        { title: "אפשרות לשירות עד בית הלקוח", icon: require('../../public/icons8-house-48.png'), size: 30 },
        { title: "15 שנות ניסיון", icon: require('../../public/experience-new.png'), size: 25 },
    ]

    const onClick = async () => {
        try {
            const phone = `tel:0547733381`;
            const canCall = await Linking.canOpenURL(phone);
            if (canCall) {
                console.log(`Will call support phone`);
                Linking.openURL(phone);
            } else {
                console.log(`Calling is not supported`);
            }
        } catch (e) {
            console.log(`Failed to call support phone ${e}`);
        }
    }

    return (
        // <View style={{ flex: 1, backgroundColor: '#FFFFFF' }}>
        <View style={{  backgroundColor: '#FFFFFF', borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        borderWidth: 1,
        borderColor: '#F0F0F0', marginTop: 10 }} onLayout={onLayout}>


            
            {/* <ImageBackground source={require('../../public/background.jpg')} style={{ flex: 1 }}>
         <ImageBackground source={require('../../public/backbdika.jpg')} style={{ flex: 1 }}> */}

            {/* <View style={styles.container}>
                <View style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#001E2B' }}>
                    <Text style={styles.title}>שירותי סלולר ומחשוב</Text>
                    <Text style={styles.subTitle}>לבית ולעסק</Text>
                </View> */}

            <View style={styles.container}>
                <View style={{ paddingTop: 30, flex: 1 }}>
                    <Text style={styles.title}>שירותי סלולר ומחשוב</Text>
                    <Text style={styles.subTitle}>לבית ולעסק</Text>
                    <View style={{ paddingRight: 25, paddingTop: 20 }}>
                        {services.map((x, index) => {
                            return (
                                <View key={x.title} style={{ flexDirection: 'row', writingDirection: 'rtl', paddingTop: 8, marginRight: index === 2 ? -8 : 0 }}>
                                    {/* <Text style={styles.bullet}>• {x.title}</Text>
                                <Image style={{ width: x.size, height: x.size, marginRight: 2 }} source={x.icon} /> */}

                                    <Image style={{ width: x.size, height: x.size, marginLeft: 2 }} source={x.icon} />
                                    <Text style={[styles.bullet, { marginRight: index === 4 ? 3 : 0 }]}>{x.title}</Text>




                                    {/* {index === 0 && <Image style={{ width: 30, height: 30, marginRight: 10 }} source={require('../../public/icons8-tools-96.png')} />}
                            {index ===  && <Image style={{ width: 30, height: 30 }} source={require('../../public/icons8-hand-with-smartphone-96.png')} />} */}
                                </View>
                            )
                        }
                        )}
                    </View>
                </View>

                {/* <View style={{flexDirection: 'row', justifyContent: 'space-between', padding: 5}}>
                <Image style={{ width: 96, height: 96 }} source={require('../../public/icons8-tools-96.png')} />
                <Image style={{ width: 96, height: 96 }} source={require('../../public/icons8-hand-with-smartphone-96.png')} />
                </View> */}
            </View>
            {/* </ImageBackground> */}
        </View>
    )
}

const useStyles = () => {
    const styles = StyleSheet.create({
        container: {
            // flex: 1,
            // backgroundColor: '#fff',
            // alignItems: 'center',
            // justifyContent: 'center',
            // paddingRight: 20,
            // backgroundColor: 'green',
            paddingBottom: 20,
            paddingTop: 40
        },
        title: {
            // fontFamily: 'Montserrat-Regular',
            fontFamily: 'OpenSans-Regular',
            fontSize: 30,
            fontStyle: 'normal',
            fontWeight: 700,
            textAlign: 'center',
            color: '#01B0C1'
            // color: '#FFFFFF'
        },
        subTitle: {
            // fontFamily: 'Montserrat-Regular',
            fontFamily: 'OpenSans-Regular',
            fontSize: 24,
            fontWeight: 500,
            textAlign: 'center',
            color: '#01B0C1'
            // color: '#FFFFFF'

        },
        button: {
            // backgroundColor: '#F0F0F0',
            backgroundColor: 'transparent',
            width: 200,
            height: 80,
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            // marginHorizontal: 20
            // marginLeft: 20,
            // marginRight: 20,
        },
        buttonText: {
            // fontFamily: 'Montserrat-Regular',
            fontFamily: 'OpenSans-Regular',
            fontSize: 20,
            color: '#FFFFFF',
            textAlign: 'right',
        },
        bullet: {
            // fontFamily: 'Montserrat-Regular',
            fontFamily: 'OpenSans-Regular',
            fontSize: 18,
            lineHeight: 24,
            fontWeight: 'bold',
            textAlign: 'center',
            alignSelf: 'center',
            color: '#001E2B'
            // writingDirection: 'rtl',
        }

    })
    return { styles };
}

export default Container;