
import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image, ImageBackground, Linking } from 'react-native';

const BottomBar = () => {

    const { styles } = useStyles();

    const onClick = async () => {
        try {
            const phone = `tel:0547733381`;
            const canCall = await Linking.canOpenURL(phone);
            if (canCall) {
                console.log(`Will call support phone`);
                Linking.openURL(phone);
            } else {
                console.log(`Calling is not supported`);
            }
        } catch (e) {
            console.log(`Failed to call support phone ${e}`);
        }
    }

    return (
        <View style={{ backgroundColor: '#F0F0F0',  paddingRight: 25, paddingTop: 20, minHeight: 150 }}>
            {/* <View style={{
                flex: 1,
                flexDirection: 'column',
                writingDirection: 'rtl',
                marginTop: 20,
                backgroundColor: '#001E2B',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Text style={styles.buttonText}>ליצירת קשר</Text>
                <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => onClick()} >
                    <Text style={styles.buttonText}>לירן</Text>
                    <Text style={[styles.buttonText, { paddingRight: 5 }]}>054-7733381</Text>
                </TouchableOpacity>
            </View> */}
            <Text style={styles.buttonText}>שורה של טקסט</Text>
            <Text style={styles.buttonText}>עוד שורה של טקסט</Text>
        </View>
    )
}

const useStyles = () => {
    const styles = StyleSheet.create({
        buttonText: {
            // fontFamily: 'Montserrat-Regular',
            fontFamily: 'OpenSans-Regular',
            fontSize: 20,
            // color: '#FFFFFF',
            textAlign: 'right',
        },
    });
    return { styles };
}


export default BottomBar;