import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image, ImageBackground, Linking } from 'react-native';

const TopBar = ({setLayout}) => {

    const onLayout = (event) => {
        const { height } = event.nativeEvent.layout;
        setLayout(height);
    }

    const onClick = async () => {
        try {
            const phone = `tel:0547733381`;
            const canCall = await Linking.canOpenURL(phone);
            if (canCall) {
                console.log(`Will call support phone`);
                Linking.openURL(phone);
            } else {
                console.log(`Calling is not supported`);
            }
        } catch (e) {
            console.log(`Failed to call support phone ${e}`);
        }
    }

    return (
        <View onLayout={onLayout} style={{ flex: 1, paddingTop: 20,  backgroundColor: '#001E2B', minHeight: 80, position: 'relative' }}>
        {/* <Text style={styles.title}>שירותי סלולר ומחשוב</Text>
        <Text style={styles.subTitle}>לבית ולעסק</Text> */}
        <View onClick={onClick} style={{position: 'absolute', right: 10, top: 10, flexDirection: 'row'}}>
        <Image source={require('../../public/icons8-phone-30.png') } style={{width: 24, height: 24, top: -2, right: 2}}/>
        
        <Text style={{color: '#01B0C1'}}>054-77333381</Text>
        </View>
    
        <Image source={require('../../public/icons8-email-24.png') } style={{width: 24, height: 24, position: 'absolute', top: 10, left: 10 }}/>
    </View>
    )
}

export default TopBar;