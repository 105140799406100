import React, { useState } from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import Contianer from './src/Components';
import { useFonts } from 'expo-font';
import Loading from './src/Components/Loading';
import TopBar from './src/Components/TopBar';
import BottomBar from './src/Components/BottomBar';

export default function App() {
  console.log('App.helllo');
  const [fontsLoaded] = useFonts({
    'Montserrat-Bold': require('./assets/fonts/Montserrat-Bold.ttf'),
    'Montserrat-Regauler': require('./assets/fonts/Montserrat-Regular.ttf'),
    'RobotoMono-Thin': require('./assets/fonts/RobotoMono-Thin.ttf'),
    'OpenSans-Regular': require('./assets/fonts/OpenSans-Regular.ttf'),
  });
  const [topBarLayout, setTopBarLayout] = useState(0);
  const [mainLayout, setMainLayout] = useState(0);
  const { styles } = useStyle(topBarLayout, mainLayout);

  if (!fontsLoaded) {
    return <Loading />
  } else {
    return (
      <View style={styles.container}>
        <TopBar setLayout={setTopBarLayout}/>
        <Image style={styles.umbrella} source={require('./public/mobile-app.png')} />
        <Contianer setLayout={setMainLayout}/>
        <Image style={styles.laptop} source={require('./public/laptop.png')} />
        <BottomBar />
      </View>
    );
  }
}
const useStyle = (topBarLayout, mainLayout) => {
const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // backgroundColor: '#fff',
    // backgroundColor: '#001E2B',
    // alignItems: 'center',
    // justifyContent: 'center',
    // minHeight: '100vh',
    // display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // color: 'black',
    backgroundColor: '#001E2B',
    position: 'relative',
  },
    umbrella: {
      width:  120,
      height: 120,
      position: 'absolute',
      top: topBarLayout - 35,
      left: 30,
      zIndex: 9
  },
  laptop: {
    // width:  120,
    // height: 120,
    // position: 'absolute',
    // top: mainLayout - 35,
    // left: 30,
    // zIndex: 9
      width: 120,
      height: 120,
      position: 'absolute',
      top: topBarLayout - 35,
      zIndex: 8,
      right: 30
      // alignSelf: 'center',
},
  });
  return { styles }
}
